import styled from '@mui/material/styles/styled'
import { graphql, PageProps } from 'gatsby'
import React from 'react'
import { DeckLayout } from '../layout/deck-layout'

const TagsTemplate: React.FC<PageProps<GatsbyTypes.Query, {name: string}>> = React.memo(({ data, pageContext }) => {
  
  return (
    <>
      <DeckLayout data={data} title={pageContext.name} frontContents={
        <Title className="container" children={`Tag: ${pageContext.name}`} />
      }/>
    </>
  )
})

const Title = styled('h1')(({ theme }) => ({
  ...theme.typography.h5,
  display: 'block',
  width: '100%',
  padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
  margin: 'auto',
  backgroundColor: theme.palette.background.z1,
  borderRadius: 0,
  [theme.breakpoints.up('md')]: {
    borderRadius: theme.shape.borderRadius,
  },
  '&.container': {
    [theme.breakpoints.up('lg')]: {
      marginRight: theme.spacing(35),
    },
    [theme.breakpoints.up('xl')]: {
      marginRight: 'auto',
    },
  }
}));

export default TagsTemplate

export const query = graphql`
query($name: [String]) {
  allFile(
    filter: {childMdx: { frontmatter: {tags: {in: $name}}}}
    sort: {fields: childMdx___frontmatter___date, order: DESC}
  ) {
    nodes {
      childMdx {
        frontmatter {
          date(formatString: "YYYY-MM-DD")
          title
          description
          image {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: DOMINANT_COLOR
              )
            }
          }
        }
        id
        body
        slug
      }
    }
  }
  allMdx {
    distinct(field: frontmatter___tags)
  }
}
`